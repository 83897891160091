$nav-width-desktop-closed: 75px;
$nav-width-desktop-opened: 250px;

.main-nav {
  position: fixed;
  background-color: $background-black;
  color: $text-light;
  padding: 30px 15px;
  height: 70px;
  width: 100%;
  top: 0;
  z-index: 20;

  // Everything after the header needs adjusting
  & + .app-content {
    padding-top: 70px;
  }

  .nav-content {
    display: none;
    opacity: 0;
    margin-top: $px25;
    color: $text-white;
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: $background-black;

    //All siblings need to be positioned to show above the overlay
    & ~ * {
      position: relative;
    }
  }

  a {
    color: $text-white;

    &.selected, &.selected:hover {
      background: $blue;
    }
  }

  .logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;

    .menu-toggle-desktop {
      display: none;
    }
  }

  h1 {
    .header-text {
      display: block;
      color: $text-white;
      background: url('../images/studio-71-full-logo-white.png') 0 0 no-repeat;
      background-size: contain;
      height: 0;
      padding-top: 20px;
      margin: 0;
      overflow: hidden;
    }
  }

  .menu-toggle {
    float: right;
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 20px;
    color: $text-white;
  }

  &.opened {
    animation: open-main-nav-drawer-mobile 300ms ease-out normal forwards;

    // Everything after the header needs adjusting
    & + .app-content {
      padding-top: 70px;
    }

    .menu-toggle {
      color: $blue;
    }

    .nav-content {
      display: block;
      left: 0;
      right: 0;
      animation: fade-in-main-nav-content 100ms ease-in 200ms forwards;
    }
  }

  &.closed {
    position: fixed;
    height: 100vh;
    animation: close-main-nav-drawer-mobile 200ms ease-out 100ms forwards;

    .nav-content {
      animation: fade-out-main-nav-content 100ms ease-in backwards;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    max-height: calc(100vh - 165px);
    overflow: hidden;

    div {
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      box-sizing: content-box;
    }

    li {
      font-size: $px16;

      a, button {
        display: block;
        width: calc(100% - 16px);
        padding:  8px;
        margin-top: 10px;
        @include border-radius();
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 200ms ease;
        font-weight: 300;
        text-align: left;
        color: $text-white;

        &:hover {
          background-color: rgba(255, 255, 255, .2);
          color: $text-white;
        }
      }
      .icon {
        display: inline-block;
        margin-right: $px10;
        font-size: $px20;
        width: $px30;
        text-align: center;
        text-shadow: 0 2px 3px rgba(0, 0, 0, .2);
      }
    }
  }

  a.contact-us {
    display: block;
    padding:  8px;
    margin-top: 10px;
    @include border-radius();
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 200ms ease;
    font-weight: 300;

    &:hover {
      background-color: rgba(255, 255, 255, .2);
    }
  }

  .icon {
    display: inline-block;
    margin-right: $px10;
    font-size: $px20;
    width: $px30;
    text-align: center;
    text-shadow: 0 2px 3px rgba(0, 0, 0, .2);
  }

  .user-info-wrapper {
    padding: $px5 0;
    border: 1px solid $text-medium;
    border-left: none;
    border-right: none;

    .user-info {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 8px;
      height: 50px;
      @include border-radius();
      transition: background-color 200ms ease;

      .initials {
        display: inline-block;
        width: $px30;
        height: $px30;
        font-size: $px16;
        line-height: $px30;
        background:$text-medium;
        background-size: cover;
        background-position: center;
        border-radius: $px15;
        color: $text-white;
        text-align: center;
        margin-right: 10px;
        text-transform: uppercase;
        flex-shrink: 0;

        span {
          display: block;
          height: 100%;
        }
      }

      .link-text {
        position: absolute;
        left: $px48;
        width: calc(100% - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: rgba(255, 255, 255, .2);
      }

      &.active, &.active:hover {
        background-color: $blue;
      }
    }
  }
}


.dimmer-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  z-index: 5;

  &.opened {
    z-index: 15;
    animation: show-nav-background-overlay 100ms forwards;
  }

  &.closed {
    opacity: .6;
    z-index: 15;
    animation: hide-nav-background-overlay 300ms 200ms forwards;
  }
}

@media(min-width: $desktop){
  .main-nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    @include box-shadow((1px 0 4px 2px rgba(0, 0, 0, 0.2)));
    background: $background-black;
    background-image:  url('../images/palmtree-background.jpg');
    background-position: 0 bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: $nav-width-desktop-closed;
    top: 0;
    bottom: 0;
    transition: width 300ms ease;

    & > * {
      opacity: 1;
      white-space: nowrap;
    }

    // Everything after the header needs adjusting
    & + .app-content {
      padding-top: 0;
      margin-left: $nav-width-desktop-closed;
      transition: margin-left 300ms ease;
    }

    .overlay {
      display: block;
      background-color: rgba(0, 0, 0, .6);
      bottom: 0;
      visibility: visible;
    }

    .logo-container {
      .menu-toggle-desktop {
        background-color: #2C2C2C;
        display: block;
        position: absolute;
        top: 0;
        right: -22px;
        padding: 0 !important;
        width: $px20;
        height: $px20;
        border-radius: 10px;
        text-align: center;
        line-height: $px20;
        color: #fff;
        transition: color 100ms ease-in;

        &:hover {
          cursor: pointer;
          color: $blue;
        }
      }
    }

    h1 {
      margin-top: 0;
      display: flex;

      .arrow-logo {
        display: block;
        background: url('../images/studio-71-arrow-logo-white.png') 0 0 no-repeat;
        width: 40px;
        height: 40px;
        top: -40px;
        background-size: contain;
      }

      .header-text {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .menu-toggle {
      display: none;
      font-size: $px12;
      margin-right: -80px;
      color: $text-medium;
      background-color: $text-white;
      width: $px20;
      height: $px20;
      @include border-radius($px10);
      line-height: $px22;
      text-align: center;
    }

    .nav-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      opacity: 1;
    }

    .link-text {
      display: none;
      opacity: 0;
      margin-left: -30px;
    }

    nav {
      display: flex;
      flex-grow: 1;
      max-height: calc(100vh - 190px);
      overflow: hidden;

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
      }

      ul {
        display: flex;
        flex-direction: column;
      }
    }

    &.opened {
      width: $nav-width-desktop-opened;
      animation: none;

      // Everything after the header needs adjusting
      & + .app-content {
        padding-top: 0;
        margin-left: $nav-width-desktop-opened;
      }

      .nav-content {
        display: flex;
        animation: none;
      }

      h1 {
        .arrow-logo {
          display: none;
        }

        .header-text {
          display: block;
          animation: fade-in-link-text-desktop 200ms ease-out forwards;
        }
      }

      .logo-container .menu-toggle-desktop {
        transform: rotate(180deg);
      }

      .link-text {
        display: inline-block;
        animation: fade-in-link-text-desktop 200ms ease-out forwards;
      }

      nav ul li {
        // Stagger the animation of each li's .link-text
        // Loop set to 10 if we eventually add more than 10 li's this will need to be increased
        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            .link-text {
              display: inline-block;
              animation: fade-in-link-text-desktop 200ms ease-out forwards;
              animation-delay: 75ms * $i;
            }
          }
        }
      }

      .menu-toggle {
        color: $text-medium;
      }
    }

    &.closed {
      animation: none;

      .nav-content {
        display: flex;
        opacity: 1;
        animation: none;
      }

      .link-text {
        display: inline-block;
        margin-left: 0;
        animation: fade-out-link-text-desktop 100ms ease-out forwards;
      }

      h1 {
        .arrow-logo {
          display: block;
        }

        .header-text {
          display: none;
          position: absolute;
          margin-left: 0;
          opacity: 1;
          animation: fade-out-header-text-desktop 100ms ease-out forwards;
        }
      }
    }
  }

  .dimmer-overlay.opened,
  .dimmer-overlay.closed {
    animation: none;
    display: none;
  }
}

// Desktop animations

@keyframes fade-in-link-text-desktop {
  from {
    display: inline-block;
    margin-left: -30px;
    opacity: 0;
  }
  to {
    display: inline-block;
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes fade-out-link-text-desktop {
  0% {
    display: inline-block;
    margin-left: 0;
    opacity: 1;
  }
  99% {
    display: inline-block;
    margin-left: 0;
    opacity: 0;
  }
  100% {
    display: none;
    margin-left: -30px;
  }
}

@keyframes fade-out-header-text-desktop {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

//Mobile Animations

@keyframes open-main-nav-drawer-mobile {
  0% {
    height: 70px;
    position: fixed;
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .1));
  }
  5% {
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .5));
  }
  100% {
    position: fixed;
    height: 100vh;
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .5));
  }
}

@keyframes close-main-nav-drawer-mobile {
  0% {
    //position: fixed;
    height: 100vh;
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .5));
  }
  90% {
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .5));
  }
  99% {
    height: 70px;
    //position: fixed;
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, .2));
  }
  100% {
    height: 70px;
    //position: relative;
    @include box-shadow(0 2px 4px 1px rgba(0, 0, 0, 0));
  }
}

@keyframes fade-in-main-nav-content {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out-main-nav-content {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes align-main-content {
  0% {
    padding-top: 70px;
  }
  99% {
    padding-top: 70px;
  }
  100%{
    padding-top: 0;
  }
}

@keyframes show-nav-background-overlay {
  from {
    opacity: 0;
    display: block;
  }
  to {
    opacity: .6;
    display: block;
  }
}

@keyframes hide-nav-background-overlay {
  0% {
    opacity: .6;
  }
  99% {
    opacity: 0;
    z-index: 15;
  }
  100% {
    opacity: 0;
    z-index: 5;
  }
}

.nav-tooltip {
  background-color: rgba(0,0,0,.7);
  color: #fff;

  .content {
    padding: $px5 $px8;
  }

  .arrow-container {
    display: none;
  }
}

.account-settings-container {
  .opaque-container {
    height: 100%;
  }

  .account-photo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    p.remove-photo {
      text-decoration: underline;
    }

    .photo-container {
      position: relative;
      height: 0;
      width: 70%;
      padding-bottom: 70%;
      margin: 20px auto;
      @include border-radius(50%);
      overflow: hidden;

      .page-loader {
        background-color: rgba(0, 0, 0, .5);
      }

      .photo-overlay, .photo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include border-radius(50%);
      }

      .photo {
        background: $background-grey url('../images/yoda.png') center no-repeat;
        background-size: cover;
      }

      .photo-overlay {
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .5);
        z-index: 5;

        button {
          z-index: 10;
          color: #fff;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    input {
      display: none;
    }

    em {
      display: block;
      color: $text-light;

      &.error {
        color: $red;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .row {
      & > div > * {
        display: block;
        margin-bottom: $px50;

        &:first-child {
          height: $px50;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .update-password-form {
        label {
          display: block;
          margin-bottom: $px40;

          span {
            display: none;
          }

          .error {
            position: absolute;
            color: $red;
            padding-top: $px5;
          }
        }
      }

      label {
        color: $text-light;
      }

      input[type=text], input[type=password]{
        display: block;
        width: 100%;
        border: none;
        border-bottom: 2px solid $purple;
        margin-top: 0;
        color: $text-dark;
        padding: 0;
        font-weight: 300;

        &::placeholder {
          color: $text-light;
        }
      }
    }

    .custom-dropdown {
      .desktop-dropdown {
        .custom-dropdown__single-value {
          text-transform: inherit;
        }
      }
    }

    .email-address {
      color: $text-light;

      span {
        color: $text-dark;
        display: block;
      }

      em {
        font-size: $px12;
        color: $text-light;
      }
    }

    .change-password {
      button {
        display: block;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      button {
        position: relative;
        flex-shrink: 0;
        width: $px100;
        height: $px40;
        margin-left: $px10;

        svg rect {
          fill: #fff;
        }
      }

      .success-message, .error-message {
        width: 100%;
        display: block;
        text-align: center;
        padding-bottom: $px10;
      }

      .success-message {
        color: $purple;
      }

      .error-message {
        color: $red;

        i {
          margin-left: $px5;
        }
      }
    }
  }

  @media(min-width: $tablet-landscape) {

    form .actions {
      justify-content: flex-end;
      flex-wrap: unset;

      .error-message, .success-message {
        display: inline;
        padding: 0 $px10 0 0;
        text-align: right;
      }
    }
  }

  @media(min-width: $desktop) {
    .account-photo-container {
      .photo-container:hover {
        .photo-overlay {
          display: flex;
        }
      }

      p.remove-photo {
        display: none;
      }
    }
  }

  @media(min-width: $desktop-xl){
    .account-photo-container .photo-container {
      width: 50%;
      padding-bottom: 50%;
    }
  }
}

.remove-photo-modal {
  .actions {
    button {
      margin-right: $px5;
    }
  }
}

.password-tooltip .content {
  width: 225px;
}

.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $background-black;
  z-index: 12;

  &.transparent {
    background-color: rgba($background-black, 0.6);
  }

  .loader-container {
    width: 150px;
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }

    &.x-small {
      width: 25px;
    }

    &.small {
      width: 50px;
    }

    &.medium {
      width: 100px;
    }

    &.large {
      width: 150px;
    }

    &.x-large {
      width: 200px;
    }
  }

  &.page-loaded {
    animation: fade-out-loader 200ms forwards;
  }
}

@media (min-width: $desktop) {
  main > .container-fluid > .page-loader .loader-container {
    margin-left: 75px;
  }

  header.opened + main > .container-fluid > .page-loader .loader-container {
    margin-left: 250px;
  }
}

@keyframes fade-out-loader {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.page-error {
  font-size: 20px;
  height: calc(100vh - 110px);
  display: flex;

  span {
    margin: 0 auto;
    text-align: center;
  }
}

@media (min-width: $tablet) {
  .page-error {
    align-items: center;
  }
}

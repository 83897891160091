.custom-dropdown {
  .desktop-dropdown {
    margin-bottom: 15px;

    label {
      display: flex;
      align-items: flex-end;
      margin: 0;

      &:focus {
        border: none;

        & > div {
          border: none;
          outline: none;
        }
      }
    }

    span.label {
      margin-right: $px10;
      width: 120px;
    }

    .custom-dropdown-desktop {
      width: 100%;

      .custom-dropdown__control {
        display: flex;
        border-bottom: 1px solid $background-grey;

        &--menu-is-open {
          @include box-shadow(0 0px 3px 1px rgba(102, 102, 102, .2));
          @include border-radius(5px 5px 0 0);
        }
      }

      .custom-dropdown__indicator {
        width: 35px;
        height: 35px;
      }

      .custom-dropdown__input input {
        margin: 0;
        padding: 0;
      }
    }

    .custom-dropdown__indicator-separator {
      display: none;
    }

    .custom-dropdown__single-value, .custom-dropdown__placeholder {
      color: $text-light;
      text-transform: uppercase;
      font-size: 1rem;
    }

    .custom-dropdown__menu {
      margin-top: -2px;
      border-top: none;
      @include box-shadow(0 3px 3px 1px rgba(102, 102, 102, .2));
      @include border-radius(0 0 5px 5px);
    }

    .custom-dropdown__menu-list {
      padding: 0;
      border: none;
      box-shadow: none;
      outline: none;

      & > div {
        background-color: #fff;
        text-align: left;

        &:last-child {
          @include border-radius(0 0 5px 5px);
        }

        &:hover {
          background-color: $blue;
          cursor: pointer;
          color: #fff;
        }

        &.custom-dropdown__option--is-selected {
          background-color: lighten($blue, 30%);
          color: $text-medium;
        }
      }
    }
  }

  .native-dropdown {
    display: inline;
    background: #fff;

    label {
      width: 100%;
      position: relative;
    }

    select {
      position: relative;
      width: 100%;
      border: none;
      border-bottom: 1px solid $text-light;
      @include box-shadow(none);
      @include border-radius(0);
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      z-index: 5;
      text-transform: uppercase;
      font-size: $px16;
      padding-right: 50px;
      color: $text-light;
      outline: none;
    }

    i {
      position: absolute;
      right: 10px;
      font-size: 10px;
      line-height: 18px;
    }
  }
}
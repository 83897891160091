.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: $background-black url('../images/palmtree-background.jpg') top center no-repeat;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
  }

  .login-container {
    position: relative;
    width: 90%;
    @include opaque-div-style();
    //padding: 25px 40px 40px;
    padding: $px25;
    z-index: 15;
  }

  .logo {
    position: relative;
    width: 100%;
    padding-bottom: 20%;

    h1 {
      position: absolute;
      top: 0;
      width: 100%;
      height: 0;
      padding-top: 100%;
      background: url('../images/s71-studio-logo.png') 0 0 no-repeat;
      background-size: 100%;
      overflow: hidden;
    }
  }

  button {
    position: relative;
    width: 100%;
    height: $px50;
    margin: $px25 auto 0;
    text-transform: uppercase;
    overflow: hidden;

    .page-loader {
      position: absolute;
      background: $blue;

      svg path, svg rect {
        fill: $text-white;
      }
    }
  }

  @media (min-width: $tablet) {
    .login-container {
      width: 400px;
    }
  }
}
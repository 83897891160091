@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "../css/bootstrap/bootstrap.min.css";
@import "../css/bootstrap/bootstrap-reboot.min.css";
@import "../css/bootstrap//bootstrap-grid.min.css";
@import "../../node_modules/@fortawesome/fontawesome-pro/css/all.css";
@import "../../node_modules/@fortawesome/fontawesome-pro/css/sharp-solid.css";

@import "./pxToRem";
@import "./variables";
@import "./mixins";

html {
  font-size: 14px;
  background-color: $background-grey;
  @import "./defaults";
  @import "./creatorStudioLogoHeader";
  @import "./pagination";
  @import "./containerTile";
  @import "./customDropdown";
  @import "./modal";
  @import "./tooltip";
  @import "./errorPage";

  body {
    @import "./pageLoader";
    @import "./loginContainer";
    @import "./opportunities";
    @import "./amazonLinkBuilder";
    @import "./mainNav";
    @import "./footer";
    @import "./postDetailContainer";
    @import "./documentsPage";
    @import "./blogPreview";
    @import "./reports";
    @import "./settingsOverview";
    @import "./channelSettings";
    @import "./addChannelModal";
    @import "./accountSettings";
    @import "./paymentSettings";
    @import "./surveys";
    @import "./pageNotFound";
    @import "./dashboard";
    @import "./ugcForm";
    @import "./contactUsForm";
    @import "./contactUsModal";
    @import "./authRoadblockModal";
    @import "./monthlyStatementEmailUnsubscribe";
  }
}

.add-channel-modal {
  .modal-content {
    overflow: visible;
  }

  .form {
    .account {
      margin-bottom: $px20;
    }

    label {
      display: flex;
      align-items: flex-end;
    }

    span.label {
      display: none;
      flex-shrink: 0;
      width: 100px;
      margin-right: $px10;
    }

    .url {
      input {
        flex-grow: 1;
        border: none;
        border-bottom: 2px solid $red;
        margin: $px20 0 $px10;
        color: $text-light;
        padding: 0 $px5;

        &::placeholder {
          color: $text-light;
          text-transform: uppercase;
          font-size: $px12;
        }
      }

      .validation-error {
        position: absolute;
        margin-top: -10px;
        font-style: italic;
        color: $red;
      }
    }
  }

  .success-message {
    p {
      padding: $px10 0;
    }
  }

  .actions {
    padding-top: $px20;

    button {
      width: 100%;
      float: right;
    }
  }

  @media (min-width: $tablet-landscape) {
    .form {
      span.label {
        display: block;
      }

      .validation-error {
        margin-left: 110px;
      }
    }

    .actions button {
      width: $px140;
    }
  }
}
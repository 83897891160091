.report-page {
  //page header styling
  .reset-title {
    color: $text-white;
  }

  .report-dropdown {
    width: 100%;
    margin-bottom: $px40;
    padding: $px15;

    .native-dropdown {
      label {
        margin: 0;
      }

      .label {
        display: block;
        padding-bottom: $px2;
      }
    }
  }

  //icon tweaks
  .fa-arrow-down, .fa-arrow-up {
    margin-left: .5rem;
  }
  .fa-arrow-down {
    color: $red;
  }
  .fa-arrow-up {
    color: $green;
  }
  .fa-chart-line {
    float: right;
    font-size: $px22;
  }

  //report header tweaks
  .monthly-report-header {
    margin-top: 1.786rem;
    text-transform: uppercase;
  }
  .report-header-row {
    padding-bottom: 1rem;
    font-weight: bold;
  }

  //row styling
  .report-header-row, .report-row {
    display: flex;
    border-bottom: 1px $background-grey solid;
    word-break: keep-all;
  }
  .report-row {
    padding: $px10 0;
  }

  //column widths for 2, 3, 4 column tables
  .two-col {
    .report-header-row, .report-row {
      li:nth-child(odd) {
        width: 70%;
      }
      li:nth-child(even) {
        width: 30%;
        text-align: center;
      }
    }
  }
  .three-col {
    .report-header-row, .report-row {
      li:nth-child(1) {
        width: 60%;
      }
      li:nth-child(n+2) {
        width: 20%;
        text-align: center;
      }
    }
  }
  .four-col {
    .report-header-row, .report-row {
      li {
        width: 25%;
      }
      li:nth-child(n+2), .cell-arrow {
        text-align: right;
      }
    }
  }
}

@media(min-width: $desktop) {
  .report-page {
    .reset-title {
      color: $text-dark;
      &:hover {
        cursor: pointer;
      }
    }
    .full-title {
      width: 60%;
    }
    .report-dropdown {
      width: 30%;
      margin-bottom: $px50;
      padding: $px12 $px18;
      .desktop-dropdown {
        margin-bottom: 0;
      }
      label {
        display: flex;
        align-items: center;
      }
      .label {
        min-width: $px115;
      }
    }
    .row .opaque-container {
      height: calc(100% - 1.429rem);
    }
  }
}

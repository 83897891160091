.creator-app-modal {
  position: fixed;
  display: block;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;

  .modal-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    animation: fade-in-modal-background 300ms forwards;
  }

  .modal-content-container {
    @include opaque-div-style();
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 75px);
    padding: $px15 $px15;
    margin: -110vh auto 0;
    opacity: 0;
    background-color: #fff;
    @include border-radius(0);
    border: none;
    animation: slide-in-modal-content-mobile 200ms forwards 200ms ease-out;
    overflow-y: scroll;
  }

  .header-container {
    display: flex;
    align-items: center;
  }

  h3 {
    margin-bottom: 0;
  }

  .header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 30px;
    height: 30px;
    background: $blue;
    margin-right: $px10;
    @include border-radius();

    & > * {
      width: 15px;
      height: 15px;
      color: #fff;
      font-size: 14px;
      text-align: center;

      &.s71-icon {
        background: url('../images/studio-71-arrow-logo-white.png') 0 0 no-repeat;
        background-size: 100%;
      }
    }
  }

  .close {
    position: absolute;
    top: $px15;
    right: $px15;

    span {
      display: none;
    }

    i {
      color: $text-dark;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .modal-content {
    max-height: calc(100vh - 165px);
    overflow: scroll;
    border: none;
    padding: $px25 0 $px10;
  }

  &.closing {
    animation: fade-out-modal 200ms forwards;
  }

  @media (min-width: $tablet-landscape) {
    display: flex;

    .modal-content-container {
      width: 600px;
      max-height: calc(100vh - 70px);
      @include border-radius();
      animation: slide-in-modal-content-desktop 200ms forwards 200ms ease-out;
      overflow-y: visible;
      padding: $px25;
    }

    .header-container {
      display: block;
      min-height: 50px;

      h3 {
        margin-left: 100px;
      }
    }

    .header-icon {
      display: flex;
      position: absolute;
      margin-top: -20px;
      margin-right: 0;
      width: 80px;
      height: 80px;

      & > * {
        width: 35px;
        height: 35px;
        color: #fff;
        font-size: 30px;
        text-align: center;

        &.s71-icon {
          background: url('../images/studio-71-arrow-logo-white.png') 0 0 no-repeat;
          background-size: 100%;
        }
      }
    }

    .modal-content {
      padding: 0 $px5;
    }
  }
}


// Animations

@keyframes fade-in-modal-background {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-modal-content-mobile {
  from {
    opacity: 0;
    margin-top: -100vh;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes slide-in-modal-content-desktop {
  from {
    opacity: 0;
    margin-top: -200px;
  }
  to {
    opacity: 1;
    margin-top: -20px;
  }
}

@keyframes fade-out-modal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.tile-list {
  .container-tile {
    padding-top: 0;
    margin-bottom: 20px;
    text-align: center;

    .opaque-container {
      height: 100%;
      padding-bottom: $px30;
    }

    h4 {
      font-size: $px18;
      margin: $px25 0;
    }

    p {
      margin: 0;
    }

    &.has-image {
      padding-top: 30px;

      .featured-image-container {
        padding-bottom: 60%;
        margin-bottom: 0;
      }

      h4 {
        margin-top: $px20;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .container-tile.empty-tile {
    .opaque-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: default;
    }
  }

  @media (min-width: $tablet) {
    .container-tile {
      min-height: 250px;

      &:last-child {
        margin-bottom: $px20;
      }
    }
  }
}

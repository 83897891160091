.payment-settings-container {
  .page-header {
    position: relativer;

    .beta-flag {
      border: 1px solid $orange;
      border-radius: 50px;
      padding: 2px 5px;
      font-size: 12px;
      background-color: $orange;
      color: $white;
      margin-left: 5px;
      position: absolute;
      top: 1px;
    }
  }
  .reset-title {
    color: $text-white;
  }

  .payment-dropdown {
    width: 100%;
    margin: 0 -10px;
    padding: $px15;

    .native-dropdown {
      label {
        margin: 0;
      }

      .label {
        display: block;
        padding-bottom: $px2;
      }
    }
  }

  .account-list {
    padding: 0;

    .custom-dropdown {
      width: 100%;

      .desktop-dropdown {
        margin-bottom: 0px;

        label {
          display: flex;
          align-items: center;
          margin: 5px;

          span.label {
            width: 140px;
          }
        }
      }
    }
  }
  .details-container {
    padding-top: 25px;

    table {
      width: 100%;

      th {
        padding: 0 0 10px 0;
      }

      tbody {
        border-top: 1px solid $background-grey;

        tr {
          border-bottom: 1px solid $background-grey;
          position: relative;
        }

        tr.noDataRow {
          height: 80px;
        }
      }

      td {
        width: calc(100% / 4);
        padding: 20px 0;
      }

      td.noDataMessage {
        color: $text-dark-red;
        position: absolute;
        top: 1px;
        width: 60%;
        font-style: italic;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@media (max-width: $tablet) {
  .payment-settings-container .details-container table td.noDataMessage {
    top: 0px;
  }
}

@media (min-width: $desktop) {
  .payment-settings-container .details-container table td.noDataMessage {
    top: 0px;
  }

  .payment-settings-container {
    .reset-title {
      color: $text-dark;
      &:hover {
        cursor: pointer;
      }
    }
    .full-title {
      width: 60%;
    }
    .payment-dropdown {
      width: 30%;
      padding: $px12 $px18;
      .desktop-dropdown {
        margin-bottom: 0;
      }
      label {
        display: flex;
        align-items: center;
      }
      .label {
        min-width: $px115;
      }
    }
    .row .opaque-container {
      height: calc(100% - 1.429rem);
    }
  }
}

// Rem values based off 14px font size
// Calculate more if needed at https://offroadcode.com/rem-calculator

$px2: 0.1429rem;
$px4: 0.2857rem;
$px5: 0.3571rem;
$px8: 0.5714rem;
$px10: 0.7143rem;
$px12: 0.8571rem;
$px13: 0.9286rem;
$px14: 1rem;
$px15: 1.071rem;
$px16: 1.143rem;
$px18: 1.286rem;
$px20: 1.429rem;
$px22: 1.571rem;
$px23: 1.643rem;
$px25: 1.786rem;
$px26: 1.857rem;
$px30: 2.143rem;
$px35: 2.5rem;
$px40: 2.857rem;
$px45: 3.214rem;
$px48: 3.429rem;
$px50: 3.571rem;
$px60: 4.286rem;
$px70: 5rem;
$px100: 7.143rem;
$px115: 8.214rem;
$px135: 9.643rem;
$px140: 10rem;
$px180: 13rem;
$px273: 19.5rem;
$px284: 20.29rem;

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  color: $text-medium;
  background-color: $background-grey;
  @include linear-gradient(($background-black 220px, $background-grey 220px));
  font-weight: 300;

  .container-fluid {
    padding: $px15;
  }

  .row {
    margin: 0 -10px;

    > * {
      padding: 10px;
    }
  }

  .opaque-container {
    @include opaque-div-style();

    .page-loader {
      position: absolute;
      background-color: transparent;
    }
  }

  .offset-header {
    margin-top: $px20;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: $text-dark;
    font-weight: 400;
    font-size: $px18;

    &.sub-header {
      color: $text-medium;
    }
  }

  header h2 {
    color: $text-white;
    padding-bottom: $px20;

    small {
      display: block;
      margin-top: $px5;
      font-style: italic;
      font-weight: 300;
      color: darken($text-white, 30%);
    }
  }

  h4, h5 {
    font-size: 1rem;
  }

  input[type=text], input[type=password] {
    border: none;
    border-bottom: 2px solid $red;
    @include border-radius(0);
    margin: $px20 0 $px10;
    color: $text-light;
    padding: 0;
    font-size: $px16;

    &::placeholder {
      color: $text-light;
      text-transform: uppercase;
      font-size: $px16;
    }
  }

  .gradient-header {
    @include gradient-header-style();

    &.blue {
      @include gradient-header-style($blue);
    }

    &.red {
      @include gradient-header-style($red);
    }
  }

  a {
    color: $blue;
    text-decoration: none;

    &:hover {
      color: darken($blue, 10%);
      text-decoration: none;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  ul, li {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    margin: 0;
  }

  button, a.button, label.button {

      // button-syle optional args { class name, color }
      @include button-style();

      &.primary {
        @include button-style('primary', $blue);
      }

      &.secondary {
        @include button-style('secondary', $red);
      }

      &.pill-button {
        @include border-radius(20px);
        padding-left: $px40;
        padding-right: $px40;

    }

    &.link {
      background: transparent;
      border: none;
      outline: none;
      color: $blue;
      padding: 0;
      animation: none;
      @include box-shadow(none);

      &:hover {
        color: darken($blue, 15%);
      }
    }
  }

  textarea {
    font-size: $px16;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  .page-nav-header {
    cursor: pointer;
    margin: 0 0 $px25 0;
    i {
      padding-right: $px5;
    }
  }

  .contact-us-button {
    text-align: center;
    margin-top: 15px;
  }

  .featured-image-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-top: -50px;

    .featured-image {
      @include featured-image-style();
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  #creator-app {
    display: block;

    .app-content {
      width: 100%;
      min-height: 100vh;
      position: relative;
      z-index: 10;

      .container-fluid {
        min-height: calc(100vh - 50px);

        &.app-footer {
          min-height: 0;
        }
      }
    }

    &.logged-out {
      .app-content {
        width: 100%;
        & > .container-fluid:first-child {
          padding: 0;
        }
      }

      & + .dimmer-overlay{
         display: none;
      }
    }
  }

  // not for production, just dev component examples, like a story book
  .ui-element-examples {
    .row > div:nth-child(5) {
      svg rect {
        fill: $text-light
      }
    }

    .opaque-container.offset-header {
     height: calc(100% - 21px);
    }
  }

  @media(min-width: $desktop){
    background: $background-grey;
    @include linear-gradient(($background-grey, $background-grey));

    .container-fluid {
      padding: $px30;
    }

    .contact-us-button {
      margin-top: 25px;
    }

    #creator-app {
      display: flex;
    }

    header h2 {
      color: $text-dark;

      small {
        color: $text-medium;
      }
    }

    input[type=text], input[type=password], textarea {
      font-size: 1rem;

      &::placeholder {
        font-size: $px14;
        font-weight: 300;
      }
    }
  }
}

.settings-overview {
  h2 {
    padding-bottom: 0;
  }
  .settings-item {
    .icon {
      @include featured-image-style();
      display: flex;
      float: left;
      width: 60px;
      height: 60px;
      margin: -35px $px20 $px15 0;
      background: $blue;
      justify-content: center;
      align-items: center;
      @include border-radius();

      i,
      svg {
        color: #fff;
        font-size: 20px;
        width: 20px;
        margin: auto;

        path {
          fill: #fff;
        }
      }
    }

    p {
      clear: both;
      font-style: italic;
      color: $text-dark;
      margin: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      .icon {
        background: $grey;
      }

      h3,
      p {
        color: $text-light;
      }

      &:hover {
        cursor: default;
      }
    }

    h3 {
      position: relative;
    }

    .beta-flag {
      border: 1px solid $orange;
      border-radius: 50px;
      padding: 2px 5px;
      font-size: 12px;
      background-color: $orange;
      color: $white;
      margin-left: 5px;
      position: absolute;
      top: 1px;
    }
  }

  @media (min-width: $desktop) {
    .settings-item {
      height: calc(100% - 2.5rem);

      p {
        margin-bottom: 1rem;
      }
    }
  }
}

.ugc-form-container {
  > button {
    margin-bottom: $px20;
  }

  h2 {
    span {
      display: none; // Hide mobile tooltip for now
      margin-left: $px10;
      font-size: $px13;
    }
  }

  form {
    position: relative;
    overflow: hidden;

    .page-loader {
      position: absolute;
      background-color: rgba(255, 255, 255, .5);
      margin: 0;
      padding: 0;
    }

    .success-message {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        margin-bottom: $px10;
        font-size: $px18;
      }
    }

    .form-content > * {
      position: relative;
      padding-bottom: $px20;
      margin-top: $px5;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    input, textarea {
      display: block;
      width: 100%;
      margin-top: $px5;
      border-color: $purple;
      border-width: 2px;
    }

    em.error-message {
      position: absolute;
      bottom: 2px;
      color: $red;
    }

    label {
      display: block;

      textarea {
        @include border-radius();
        min-height: 100px;
      }
    }

    .custom-dropdown {
      .desktop-dropdown {
        margin: 0;
      }

      label {
        display: block;

        .label {
          display: block;
          width: 100%;
        }
      }

      .custom-dropdown-desktop {
        max-width: 250px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .global-policy {
    display: block;

    & > label {
      position: relative;
      padding-bottom: $px25;
      margin-top: $px20;
      margin-bottom: (-$px25);
      width: 100%;

      input[type=text] {
        flex-grow: 1;
        align-self: flex-end;
        height: 30px;
        margin-bottom: 0;
      }
    }
  }

  .timestamp > div {
    span {
      line-height: $px35;

      input {
        display: inline;
        width: auto;
        margin-right: $px5;
      }
    }
  }

  @media (min-width: $tablet-landscape){
    h2 span {
      display: inline;
    }

    .global-policy {
      display: flex;

      .custom-dropdown label {
        width: 250px;
        margin-right: $px20;
      }
    }

    .timestamp > div {
      display: flex;

      input[type=text]{
        width: 350px;
        margin-right: $px20;
      }
    }
  }
}

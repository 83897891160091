.opportunities-container {
  .gradient-header {
    margin-top: 0;
  }

  .opportunities-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .opportunity {
    margin: $px10 0;
    text-align: center;

    .opaque-container {
      p {
        color: $text-medium;
      }
      cursor: pointer;
    }
    .opportunity-text {
      line-height: 1.8rem;
      overflow: hidden;
      height: 95px;
    }
  }

  .amazon-link-builder-tile {
    padding-bottom: $px30;

    .opaque-container {
      align-items: center;
      cursor: pointer;
      padding: $px20;
    }

    .tile-image {
      height: 45px;
      background: url('../images/amazonAssociates.png') center left no-repeat;
      background-size: contain;
      margin: 0 0 $px10;
    }

    p {
      margin-bottom: 0;
    }

    a {
      margin-right: $px5;
    }
  }

  .amazon-link-builder-tile.no-affiliate-code {
    cursor: default;
    pointer-events: none;
    h6, p, a, span {
      color: $text-light;
    }
    img, .featured-image {
      filter: grayscale(100%);
    }
  }

  @media (min-width: $tablet-landscape){
    .amazon-link-builder-tile {
      .opaque-container {
        display: flex;
        height: 120px;
      }

      .tile-image {
        width: 31%;
        height: 100%;
        margin: 0 $px25 0 0;
      }

      h3 {
        margin-bottom: $px4;
      }
    }
  }
}

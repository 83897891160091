.auth-roadblock {
  .action-buttons {
    button {
      &.link {
        color: $text-light;
        margin-left: $px10;
        transition: color 100ms;

        &:hover {
          color: darken($text-light, 10%);
        }
      }
    }
  }

  @media (min-width: $tablet-landscape) {
    p {
      margin: $px15 0 $px25;
    }
  }
}

.creator-studio-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  padding: $px12 $px25;
  background: #000;

  svg {
    height: 100%;
    path {
      fill: #fff;
    }
  }

  @media (min-width: $tablet-landscape){
    justify-content: flex-start;
  }
}
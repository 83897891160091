.blog-preview-area {
  h4 {
    margin-bottom: $px15;
    font-size: $px16;
  }

  .blog-post-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $background-grey;
    padding-top: $px15;
    margin-top: $px15;
  }

  .blog-preview-item {
    margin-bottom: $px25;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &.has-image {
      margin-top: 75px;

      .featured-image-container {
        padding-bottom: 56.25%;
        margin-top: -75px;
      }

      .blog-content {
        margin-top: 25px;
      }

      &:first-child {
        margin-top: 60px;
      }
    }
  }

  @media (min-width: $desktop) {
    h4 {
      margin-bottom: $px20;
    }

    .blog-post-footer {
      clear: both;
      margin-top: $px20;
      padding-top: $px15;
    }

    .blog-preview-item {
      margin-bottom: $px35;
      text-align: left;

      &.has-image {
        margin-top: 50px;

        .featured-image-container {
          float: left;
          width: 400px;
          height: 220px;
          margin-top: -50px;
          padding: 0;
          margin-right: $px20;
        }

        .blog-content {
          margin-top: 0;
          min-height: 170px;
        }

        &:first-child {
          margin-top: 50px;
        }
      }

      &.has-image:nth-child(even) .featured-image-container {
        float: right;
        margin-right: 0;
        margin-left: $px20;
      }
    }
  }
}
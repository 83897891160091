.documents-page {
  .opaque-container {
    overflow: hidden;
    min-height: 200px;

    .no-documents {
      height: 100vh;
    }

    & > p {
      margin: 0;
    }

    .page-loader {
      background-color: #fff;
      align-items: flex-start;

      .loader-container {
        margin-top: 50px;
      }
    }
  }

  .custom-dropdown {
    margin-bottom: $px20;

    .desktop-dropdown label {
      align-items: center;

      .custom-dropdown-desktop {
        width: 250px;
      }
    }
  }

  .documents-list {
    li {
      display: flex;
      position: relative;
      border-bottom: 1px solid $background-grey;
      padding: $px20 $px10;

      &:nth-child(2) {
        padding-top: 0;
      }

      div.item-data {
        display: flex;
        max-width: calc(100% - 2.5rem);
        min-width: calc(100% - 2.5rem);

        & > div {
          &.date {
            position: absolute;
            bottom: 12px;
            order: 3;
            font-size: $px12;
          }

          &.account,
          &.type {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.account {
            order: 1;
            padding-right: $px4;
            margin-top: -6px;

            &:after {
              content: " - ";
            }

            &:empty {
              padding-right: 0;

              &:after {
                content: "";
              }
            }
          }

          &.type {
            order: 2;
            margin-top: -6px;
          }
        }
      }

      div.save {
        display: flex;
        justify-content: flex-end;
        width: $px35;

        a {
          @include button-style("primary", $blue);
          display: block;
          width: $px25;
          height: $px25;
          padding: 0;

          span {
            display: none;

            &.icon {
              display: block;
              margin: auto;
              text-align: center;
              line-height: $px25;
            }

            i {
              color: #fff;
            }
          }
        }
      }

      &.list-header {
        display: none;
        font-weight: bold;
      }
    }
  }

  @media (min-width: $mobile-landscape) {
    .custom-dropdown {
      margin-bottom: $px15;
      padding-left: $px13;
    }

    .documents-list {
      li {
        padding: $px14;

        &:first-child {
          padding-top: 0;
        }

        &:nth-child(2) {
          padding-top: $px14;
        }

        &.list-header {
          display: flex;

          .list-item span {
            cursor: pointer;
            margin-right: 5px;
          }
        }

        div.item-data {
          display: flex;
          align-items: center;

          & > div {
            display: inline-block;
            width: 30%;
            line-height: $px22;

            &.date {
              position: static;
              font-size: 1rem;
              order: 1;
            }

            &.account {
              padding-right: $px4;
              margin-top: 0;
              order: 2;

              &:after {
                content: "";
              }
            }

            &.type {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

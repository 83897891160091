.pagination-wrapper {
  padding: 25px 0;

  ul {
    display: flex;
    justify-content: center;
  }

  li {
    display: block;
  }

  button {
    width: $px30;
    height: $px30;
    padding: 0;
    background: transparent;
    color: $text-medium;
    animation: none;
    @include border-radius($px15);
    @include box-shadow(none);

    &:hover {
      animation: none;
      background: transparent;
      color: $text-dark;
    }

    &.active {
      @include button-style('primary', $blue);
      padding: 0;
      background-color: $blue;
      animation: none;
      @include border-radius($px15);

      &:hover {
        animation: none;
        background-color: $blue;
        cursor: default;
      }
    }

    span.text {
      display: none;
    }
  }
}

$background-grey: #EDEEEE;
$background-black: #333;

//Button, link and highlight colors
$grey: #888;
$blue: #30A7FF;
$red: #EE436D;
$green: #43a646;
$purple: #9C27B0;

$text-dark: #101010;
$text-light: #9b9b9b;
$text-medium: #555;
$text-white: #f6f6f6;
$text-dark-red: #a50f15;

// beta flag colors
$white: #ffffff;
$orange: orange;

//Dropdown colors
$dropdown-text: #a0a0a0;
$dropdown-underline: #dadada;

//breakpoint values
$mobile: 330px;
$mobile-landscape: 576px;
$tablet: 768px;
$tablet-landscape: 960px;
$desktop: 992px;
$desktop-xl: 1440px;

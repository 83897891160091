.surveys-container {
  .page-header {
    margin-bottom: $px20;
  }

  .featured-image {
    background-size: cover;
  }

  .container-tile-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .container-tile {
    &.has-image {
      .featured-image-container {
        width: 100%;
        height: 0;
        padding-top: 56.25%;
      }

      .container-tile-content {
        margin-top: $px12;
      }
    }
  }

  .button {
    width: 100%;
    text-transform: uppercase;
  }

  @media (min-width: $tablet-landscape) {
    .opaque-container {
      display: flex;
      justify-content: space-between;
    }

    .container-tile-content {
      text-align: left;

      & > p {
        margin-bottom: 0;
      }
    }

    .container-tile {
      &.has-image {
        .opaque-container {
          justify-content: flex-start;
          align-items: center;

          .button {
            margin-left: auto;
            height: 100%;
            width: auto;
          }

          .container-tile-content {
            padding-left: $px20;
          }
        }
        .featured-image-container {
          width: 17%;
          height: 130px;
          padding: 0;
        }
      }
    }
  }
}

.amazon-link-builder-container {
  & > button {
    margin-bottom: 25px;
  }
  .amazon-link-builder-area, .amazon-link-builder-area-not-set-up {
    margin-bottom: $px20;
  }
  .amazon-link-builder-area-not-set-up {
    p {
      padding: 10px 10px 0;
      font-size: 16px;
    }
  }

  .amazon-link-builder-instructions {
    margin: 0 auto;
  }
  .link-builder-type-toggles {
    display: flex;
    justify-content: space-around;
    .link-builder-type-toggle {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: capitalize;
      border-radius: $px5;
      border: none;
      padding: 0.7143rem 1.071rem;
      cursor: pointer;
      color: #666;
      background-color: #eee;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      i {
        font-size: $px30;
        padding: $px15;
      }
      span {
        text-align: center;
      }
    }
    .link-builder-type-toggle.active {
      color: #fff;
      background-color: $red;
      box-shadow: 0 1px 2px 1px rgba(238, 67, 109, 0.3);
    }
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: capitalize;
      i {
        font-size: $px30;
        padding: $px15;
      }
    }
  }
  .single-item-link-builder, .multi-item-link-builder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $px40 $px20 $px30 $px20;
    min-height: 300px;
    h3 {
      margin-bottom: $px20;
    }
    p, div.original-link {
      margin-bottom: $px30;
    }
    div.textInput {
      height: 90px;
      .custom-dropdown {
        span.label {
          width: 245px;
        }
      }
    }
    div.textInput.multiple-codes {
      height: 150px;
    }
    input[type=text], textarea {
      border: none;
      border-bottom: $px2 solid $purple;
      width: 100%;
      padding: $px5;
    }
    input[type=text].error, textarea.error {
      background-color: #EFECFE;
      border: 2px solid $purple;
      border-radius: 4px;
      margin-bottom: 0px;
    }
    p.error {
      margin-top: $px2;
    }
    div.multi-text-input {
      height: 170px;
      p.error {
        margin-top: -$px2;
      }
      textarea {
        border: $px2 solid $purple;
        border-radius: $px5;
      }
    }
    div.multi-text-input.multiple-codes {
      height: 240px;
      textarea {
        margin-top: $px15;
      }
    }
    .short-link-display {
      display: flex;
      align-items: center;
      padding: $px15;
      margin-bottom: $px20;
      background: #F2F2F2;
      border-radius: $px5;

      input.short-link {
        position: absolute;
        left: -9999px;
        opacity: 0;
      }

      p.short-link {
        font-size: 1.1rem;
        color: $blue;
        font-weight: bold;
        margin-right: $px10;
        margin-bottom: 0;
      }
      button {
        width: 110px;
        span.spinner-border {
          width: 1.2rem;
          height: 1.2rem;
          border-width: 0.15em;
        }
      }
    }
    p.link-creation-error {
      padding: $px15;
      margin-bottom: $px30;
      background: #F2F2F2;
      border-radius: $px5;

    }
    p.original-link, div.original-link {
      word-break: break-all;
      font-size: 0.9rem;
      span {
        margin-right: $px5;
      }
      p.original-link-part {
        margin: $px5 0;
      }
    }
  }

  .amazon-link-builder-instructions {
    h3 {
      text-align: center;
      margin: $px20 0 $px30 0;
      font-size: $px20;
    }
    .instruction {
      display: flex;
      justify-content: space-between;
      margin-bottom: $px20;
      padding: $px20 $px30;
      div {
        width: 45%;
      }
      i {
        width: 45%;
        font-size: $px50;
        text-align: center;
        margin: auto 0;
      }
    }
    .full-guidelines-button {
      text-align: center;
      margin: $px20;
    }
  }
}

.amazon-link-builder-fineprint {
  margin: $px15 auto;

  a {
    margin-left: $px4;
    text-transform: lowercase;
  }
}

@media (min-width: $desktop) {
  .amazon-link-builder-container {
    .page-nav-header {
      display: none;
    }
    .link-builder-and-instructions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .amazon-link-builder-area, .amazon-link-builder-area-not-set-up {
      margin: 0px;
      width: 68%;
    }
    .amazon-link-builder-area-not-set-up {
      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .amazon-link-builder-area {
      display: flex;
      flex-direction: row;
      padding: $px50;
      justify-content: space-between;

      .link-builder-type-toggles {
        display: block;
        margin: 0 $px20 0 0;
        padding: 0;
        flex-shrink: 0;

        .link-builder-type-toggle {
          margin: 0;
          text-align: center;

          &:first-child {
            margin-bottom: $px40;
          }
        }
      }
      .link-builder-area {
        position: relative;
        display: block;
        flex-direction: row;
        width: 80%;
        flex-grow: 1;

        div.textInput {
          .custom-dropdown {
            span.label {
              width: 190px;
            }
          }
        }

        button {
          float: right;
          margin-left: auto;
        }

        & > div {
          display: block;
          width: 100%;
          padding-top: 0;

          p {
            margin-bottom: $px50;
          }

          &.page-loader {
            display: flex;
          }
        }
      }
    }
    .amazon-link-builder-instructions {
      width: 30%;
      margin: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .instruction {
        padding: 0;
        div {
          width: 68%;
        }
        i {
          width: 30%;
        }
      }
    }
    .amazon-link-builder-fineprint {
      padding: $px5;
    }
  }
}

.page-not-found-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $background-black url('../images/palmtree-background.jpg') top center no-repeat;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .contents {
    z-index: 99;

    h2, p {
      padding-bottom: 0;
    }

    p {
      font-size: $px15;
      color: $text-white;
    }

    h2 {
      font-size: $px30;
      margin-top: -$px15;
      p {
        font-size: 150px;
        margin: 0;
      }
    }
  }
}

@media (min-width: $desktop) {
  .page-not-found-container {
    h2 {
      color: $text-white;
    }
  }
}


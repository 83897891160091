.monthly-statement-email-unsubscribe-container {
  .opaque-container {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .start-contents {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .success-contents {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .show-loader {
    display: block;
    position: absolute;
    bottom: 22px;
    width: 100%;
    left: 0;
  }

  .hide-loader {
    display: none;
  }

  svg {
    height: 60px;
    width: 100%;
    margin: 15px 0 0 0;
  }

  p {
    text-align: center;
    padding-bottom: 15px;
    font-size: 18px;
  }

  button {
    display: flex;
    margin: 0 auto;
  }

  button.loading {
    pointer-events: none;
    background-color: lighten($red, 20%);
  }

  .error-message {
    font-size: 16px;
    padding: 30px 0 0 0;
    color: $text-dark-red;
  }
}

.post-detail-container {
  width: 100%;
  margin: 30px auto;

  &.has-image {
    margin-top: 75px;

    .featured-image-container {
      margin-top: -75px;
    }

    .post-content-wrapper {
      margin-top: 25px;
    }

    &:first-child {
      margin-top: 60px;
    }
  }

  h3 {
    margin-bottom: $px30;
    .subheader {
      display: block;
      margin-top: $px30;
      color: $text-light;
    }
  }

  .post-content-body {
    p, h1, h2, h3, h4, h5, h6, em, i, span, strong {
      text-align: left !important;
      font-weight: normal !important;
      color: $text-medium !important;
    }

    strong {
      color: $text-dark;
    }

    h1, h2 {
      display: none;
    }

    ul {
      margin: $px10;
    }

    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: $px10;
    }

    table {
      width: auto !important;
    }
  }

  @media (min-width: $desktop) {
    &.has-image {
      margin-top: 70px;
      float: left;
      clear: both;

      .featured-image-container {
        float: right;
        width: 35%;
        margin-top: -80px;
        padding-bottom: 19.67%;
        margin-left: $px20;
        margin-bottom: $px20;
      }

      .post-content-wrapper {
        margin-top: $px5;
      }

      &:first-child {
        margin-top: 50px;
      }
    }
  }
}

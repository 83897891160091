.app-footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: $background-black;
  padding-top: $px20;
  padding-bottom: $px20;
  font-size: $px8;
  z-index: 15;

  ul {
    display: flex;
    justify-content: space-around;

    li {
      width: auto;
      color: $text-white;
      line-height: $px8;
      text-align: center;

      &:first-child {
        padding-left: 0;
        border: none;
      }
    }

    &.privacy-links {
      margin: auto;

      li {
        padding: 0 $px8;
        border-left: 1px solid $text-white;

        &:first-child {
          padding-left: 0;
          border: none;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &.company-info {
      width: 100%;
      margin-top: $px15;

      li {
        width: 50%;
        text-align: left;
        padding-left: $px10;

        &:first-child {
          text-align: right;
          padding-right: $px10;
        }
      }
    }
  }

  a {
    color: $text-white;
    text-decoration: underline;
    margin: auto;
  }

  @media (min-width: $tablet) {
    justify-content: space-between;
    flex-wrap: nowrap;
    font-size: $px10;

    ul {
      width: 50%;
      justify-content: flex-start;

      &.privacy-links {
        margin: 0;
        flex-grow: 1;
      }

      &.company-info {
        justify-content: flex-end;
        width: auto;
        margin-top: 0;

        li {
          width: auto;
          margin-left: $px20;
          text-align: center;
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      li {
        height: $px10;
        line-height: $px10;
      }
    }
  }

  @media (min-width: $desktop) {
    font-size: $px12;

    ul.privacy-links li {
      padding: 0 $px8;
    }

    li {
      line-height: $px12;
    }
  }
}
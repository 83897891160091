.s71-tooltip {
  position: absolute;
  z-index: 100;
  background-color: #fff;
  @include border-radius();
  @include box-shadow(0 0 3px 1px rgba(0,0,0,0.1));
  animation: fadein-tooltip 100ms ease-in forwards;
  max-width: 500px;

  &.hidden {
    animation: fadeout-tooltip 100ms ease-in forwards;
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 14px;
    overflow: hidden;

    div {
      background: #fff;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      @include box-shadow(0 0 3px 1px rgba(0,0,0,0.1));
    }

    &.top, &.top-right, &.top-left {
      bottom: -14px;

      div {
        margin-top: -7px;
      }
    }

    &.bottom, &.bottom-left, &.bottom-right {
      top: -14px;

      div {
        margin-top: 7px;
      }
    }

    &.right {
      flex-direction: column;
      width: 14px;
      height: 100%;
      left: -14px;

      div {
        margin-left: 7px
      }
    }

    &.left {
      flex-direction: column;
      width: 14px;
      height: 100%;
      right: -14px;

      div {
        margin-left: -7px
      }
    }

    &.top-left, &.bottom-left {
      justify-content: flex-end;
      padding-right: 33px;
    }

    &.top-right, &.bottom-right {
      justify-content: flex-start;
      padding-left: 33px;
    }
  }

  .content {
    padding: $px10 $px20;
  }
}

@keyframes fadein-tooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout-tooltip {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.contact-us-form {
  height: 300px;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: $px284;

    input, textarea {
      border: none;
      border-bottom: 1px solid $text-light;
      margin: $px20 0 $px10;
      padding: 0 $px5;
      color: $text-light;
    }

    label {
      display: none;
    }

    .subject {
      input {
        width: 50%;
      }
      input::placeholder {
        text-transform: capitalize;
      }
    }

    .message {
      display: flex;
      margin-top: auto;

      textarea {
        flex-grow: 1;
        resize: none;
        //1000 characters = max 13 lines of text * 21px line height = 273px
        max-height: $px273;
      }

      &.error {
        border-bottom: 2px solid $red;
      }
    }

    .missing-message {
      color: $red;
    }
  }

  .actions {
    padding-top: $px40;
    display: flex;
    justify-content: flex-end;

    button {
      width: 100%;
    }
  }

  &.error, &.success {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .actions {
      justify-content: center;
    }
  }

  @media (min-width: $tablet-landscape) {
    .actions button {
      width: $px140;
    }
    .actions button.reset {
      width: $px180;
    }
  }
}


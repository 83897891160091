.channel-settings-page {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    i {
      display: none;
    }
  }

  .opaque-container {
    margin-top: 50px;
    padding-bottom: $px5;

    .gradient-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $px10;

      span {
        display: block;
        width: $px20;
        font-size: $px20;
        text-align: center;

        svg rect, svg path {
          fill: #fff;
        }
      }
    }
  }

  .row {
    margin-bottom: 0;
  }

  .channel-settings-item {
    display: flex;
    align-content: center;
    height: 100%;
    background: $background-grey;
    color: $text-dark;
    @include border-radius();

    & > div {
      display: flex;
      padding: $px10;
      min-height: 55px;
      align-items: center;
    }
  }

  .detail-container {
    flex-grow: 1;
  }

  .icon-container {
    flex-direction: column;
    justify-content: space-around;
    flex-shrink: 0;
    width: 50px;
    padding-right: $px20;
    border-right: 3px solid #fff;
    font-size: $px13;
    white-space: nowrap;

    i {
      font-size: 20px;
    }

    div {
      width: 18px;
    }

    span {
      display: none;
    }
  }

  .podcasts .icon-container {
    display: none;
  }

  .channel-thumbnail {
    display: none;
    flex-shrink: 0;
    width: 65px;
    height: 65px;
    background-size: cover;
    margin-right: $px10;
    font-size: 30px;
    text-align: center;
    color: $text-light;
    line-height: 55px;
  }

  .channel-title {
    flex-grow: 1;
    padding-right: $px10;

    a {
      color: $text-dark;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .channel-status {
    display: block;
    flex-shrink: 0;
    text-transform: uppercase;
    padding: $px8 $px5;
    @include border-radius();
    width: $px100;
    text-align: center;

    i {
      display: none;;
    }

    &.not-authorized {
      background-color: $red;
      color: #fff;
    }

    &.missing {
      background-color: $blue;
      color: #fff;
    }

    &.authorized {
      background-color: #fff;
      color: $blue;
    }
  }

  @media (min-width: $mobile-landscape) {
    .header-container {
      button {
        width: $px100;
      }

      i {
        display: inline;
        margin-right: $px10;
      }
    }

    .channel-settings-item > div {
      min-height: 85px;
    }

    .channel-thumbnail {
      display: block;
    }

    .icon-container {
      width: 80px;

      span {
        display: block;
      }
    }

    .channel-status {
      width: $px140;
      padding: $px10 $px10;

      i {
        display: inline;
        margin-right: $px10;
      }
    }
  }

  @media (min-width: $desktop) {
    .opaque-container {
      padding-bottom: $px10;

      .gradient-header {
        width: 400px;
      }
    }

    .channel-settings-item {
      & > div {
        padding: $px15;
        min-height: 80px;
      }
    }

    .icon-container {
      width: 100px;

      i {
        font-size: 25px;
      }

      div {
        width: 22px;
      }

      span {
        display: block;
      }
    }

    .channel-thumbnail {
      display: block;
      width: 55px;
      height: 55px;
    }

    .channel-status {
      padding: $px10 $px15;
    }
  }
}

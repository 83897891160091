.dashboard {
  h2 {
    span:last-child {
      display: none;
    }
  }

  .row > * {
    padding-top: 0;
  }

  .dashboard-tile, .blog-preview-area {
    min-height: 300px;

    h4 {
      font-size: $px16;
      text-align: center;
    }
  }

  .blog-preview-area {
    .has-image:nth-child(even), .has-image:nth-child(odd) {
      .featured-image-container {
        float: none;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        margin-bottom: $px20;
      }
    }

    h4 {
      margin-top: $px25;
    }

    h4, .blog-content > div {
      text-align: center;
    }
  }

  .page-loader {
    position: absolute;
    background-color: transparent;
  }
  .section-header {
    color: #888;
    text-align: center;
    text-shadow: 0px 1px 0px #fff;
    padding-bottom: $px5;
  }
  .container-tile {
    padding-top: 0;
    margin-top: $px20;
  }
  .container-tile.has-image {
    padding-top: 0;
    margin-top: $px50;
  }
  .blog-preview-item, .empty-dashboard-tile {
    margin-top: $px20;
  }
  .dashboard-tile article {
    height: calc(100% - 66px);

    &.has-image {
      height: calc(100% - 96px);
    }
  }

  .page-error {
    @include opaque-div-style();
  }

  .notification-section {
    margin-bottom: $px15;

    h3 {
      margin-bottom: $px25;
    }

    li {
      display: flex;
      align-items: center;
      background-color: $blue;
      @include border-radius();
      padding: $px10;
      margin-top: $px10;
      min-height: 80px;

      &:first-child {
        margin-top: 0;
      }

      &.clickable {
        background-color: $red;
        cursor: pointer;
      }

      > p {
        margin-bottom: 0;
        color: #fff !important;
        font-size: 1rem !important;

        > strong {
          margin-right: 5px;
          text-transform: uppercase;
          font-weight: bold;
        }

        > span {
          margin-left: 5px;
        }

      }

      img {
        display: none;
      }
    }
  }

  .contact-form {
    margin-top: $px20;

    .actions {
      justify-content: center;
    }
    .header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $px60;
      height: $px60;
      background: $red;
      margin-right: $px10;
      position: absolute;
      top: (-$px20);
      @include border-radius();
    }
    .fa-envelope {
      width: $px23;
      height: $px23;
      font-size: $px23;
      color: #fff;
      text-align: center;
    }
    .header-title {
      margin-left: $px70;
    }
  }
}

@media (min-width: $tablet) {
  .dashboard {
    h2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: $px20;

      span:last-child {
        display: block;
      }
    }

    .notification-section, .contact-form {
      margin-bottom: $px30;
    }

    .contact-form {
      .opaque-container {
        height: 370px;
        margin-top: (-$px20);

        .contact-us-form {
          .actions {
            justify-content: flex-end;
          }

          &.success, &.error {
            .actions {
              justify-content: center;
            }
          }
        }
      }
    }
    .notification-section {
      display: flex;

      .opaque-container {
        flex-grow: 1;
        height: 370px;

        > ul {
          height: calc(100% - 3.286rem);
          overflow-y: auto;
        }
      }
    }
  }
}

@mixin box-shadow($args) {
	-webkit-box-shadow: $args;
	-moz-box-shadow: $args;
	box-shadow: $args;
}

@mixin border-radius($args: 5px) {
	-webkit-border-radius: $args;
	-moz-border-radius: $args;
	border-radius: $args;
}

@mixin linear-gradient($args) {
	background: -webkit-linear-gradient($args);
	background: -moz-linear-gradient($args);
	background: -o-linear-gradient($args);
	background: linear-gradient($args);
}

@mixin opaque-div-style($backgroundColor: #fff){
  position: relative;
  background-color: $backgroundColor;
  @include box-shadow((0 0 2px 1px rgba(150, 150, 150, 0.3)));
  @include border-radius();
  padding: $px20 $px10;
  word-break: break-word;

  img {
    max-width: 100%;
  }

  &.disabled {
    .icon, .offset-header {
      background: $grey;
    }

    h2, h3, p, span {
      color: $text-light;
    }

    img, .featured-image {
      filter: grayscale(100%);
    }

    &:hover {
      cursor: default;
      pointer-events: none;
    }
  }

  @media (min-width: $desktop) {
    padding: $px20;
  }
}

@mixin featured-image-style(){
  @include border-radius();
  @include box-shadow(0 4px 4px rgba(0, 0, 0, 0.17));
  background-size: cover;
  background-position: center;
}

@mixin button-style($button-class: '', $button-color: $grey){
  @include border-radius();
  @include box-shadow(0 1px 2px 1px rgba($button-color, 0.3));
  background-color: $button-color;
  color: #fff;
  border: none;
  padding: $px10 $px15;
  animation: #{$button-class}-button-hover-off 200ms ease-in-out;

  &:hover {
    animation: #{$button-class}-button-hover-on 200ms ease-in-out;
    background-color: darken($button-color, 10%);
    cursor: pointer;
  }

  &:active {
    background-color: darken($button-color, 15%);
  }

  &:focus {
    @include box-shadow('none');
    outline: none;
  }

  &.disabled, &.disabled:hover, &.disabled:active {
    background-color: lighten($button-color, 30%);
    pointer-events: none;
    cursor: default;
    animation: none;
  }

  @keyframes #{$button-class}-button-hover-on {
    from {
      background-color: darken($button-color, 0%);
      @include box-shadow(0 1px 2px 1px rgba(darken($button-color, 0%), 0.3));
    }
    to {
      background-color: darken($button-color, 10%);
      @include box-shadow(0 1px 2px 1px rgba(darken($button-color, 10%), 0.3));
    }
  }
  @keyframes #{$button-class}-button-hover-off {
    from {
      background-color: darken($button-color, 10%);
      @include box-shadow(0 1px 2px 1px rgba(darken($button-color, 10%), 0.3));
    }
    to {
      background-color: darken($button-color, 0%);
      @include box-shadow(0 1px 2px 1px rgba(darken($button-color, 0%), 0.3));
    }
  }
}

@mixin gradient-header-style($color: $blue) {
  padding: $px20;
  margin-bottom: $px20;
  margin-top: -$px45;
  color: #fff;
  font-size: $px16;
  font-weight: 400;
  background-color: $color;
  @include linear-gradient((90deg, lighten($color, 5%), darken($color, 10%)));
  @include border-radius();
  @include box-shadow(0 4px 4px 0 rgba(darken($color, 10%), 0.3));

  @media (min-width: $tablet) {
    padding: $px25 $px20;
  }
}